import { createApp } from 'vue'
import App from './App.vue'
import router from "@/router";
import vuetify from './plugins/vuetify';
import InstantSearch from 'vue-instantsearch/vue3/es';
import {VueFire, VueFireFirestoreOptionsAPI} from 'vuefire'
import {firebaseApp} from "@/firebase/db";




const app = createApp(App)

app.use(VueFire, {
        // imported above but could also just be created here
        firebaseApp,
        modules: [
            // we will see other modules later on
            // VueFireAuth(),
            VueFireFirestoreOptionsAPI(),
        ],
    })

app.use(vuetify)
app.use(router)
app.use(InstantSearch)
app.mount('#app')