import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
    apiKey: "AIzaSyDsoebrYtrQc2nLO4kfdukKjnVvUkuEhdc",
    authDomain: "bricksbase.firebaseapp.com",
    projectId: "bricksbase",
    storageBucket: "bricksbase.appspot.com",
    messagingSenderId: "694061347706",
    appId: "1:694061347706:web:703c8a7918796c5d1fdcc3",
    measurementId: "G-P1GHR1PSW9"
};

export const firebaseApp = initializeApp(firebaseConfig)

export const db = getFirestore(firebaseApp)// Initialize Firebase
