<template id="app">
  <div>
    <v-app app class="pa-0 ma-0">

      <!-- Sizes your content based upon application components -->
      <v-main app class="pa-0 ma-0">
        <NavigationBar app></NavigationBar>
        <router-view app></router-view>
      </v-main>

    </v-app>
  </div>
</template>

<script>


import NavigationBar from "@/components/NavigationBar";
export default {
  name: 'App',
  components: {
    NavigationBar
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
