<template>
  <v-app-bar >
    <v-btn class="ml-10" @click="$router.push('/')"><v-app-bar-title class="font-weight-bold" >BricksBase.io</v-app-bar-title></v-btn>

    <template v-slot:append>
      <v-btn class="mr-10" color="#3B82F6" variant="outlined" @click="$router.push('/')">Se Connecter</v-btn>
    </template>
  </v-app-bar>
</template>


<script>


export default {

  data: () => ({
    drawer: false,

  }),
}
</script>

y